import React from 'react';
import Link from 'next/link';

import { useAuth } from '../../hooks/auth';
import AuthUserDropdownMenu from '../user-dropdown-menu';

export const UserProfileUtils = () => {
    const { isAuthenticated } = useAuth({ middleware: 'guest' })
    return (
        <React.Fragment>
            {isAuthenticated && (<AuthUserDropdownMenu />)}

            {
                !isAuthenticated && (
                    <React.Fragment>
                        <Link href="/auth/login" className="inline-block uppercase leading-none px-3 py-3 rounded text-sm text-gray-700 border-gray-500 hover:border-2 hover:text-blue-800 hover:underline">
                            Login
                        </Link>
                        <span className="text-gray-600 text-xs">|</span>
                        <Link href="/auth/register" className="inline-block uppercase leading-none px-3 py-3 text-sm rounded text-gray-700 border-gray-500 hover:border-2 hover:text-blue-800 hover:underline">
                            Register
                        </Link>
                    </React.Fragment>
                )
            }

        </React.Fragment >
    )
}