import { useEffect, useState } from 'react'
import useSWR from 'swr'
import axios from '../lib/axios'
import { flatten } from '../utils/helper';

export const useCategory = ({ initialData, slug, id }) => {
    const [category, setCategory] = useState(null);
    const [children, setChildren] = useState([]);
    const { data: categories, error, revalidate } = useSWR('/api/v1/categories', () =>
        axios
            .get('/api/v1/categories')
            .then(res => res.data)
            .catch(error => {
                if (error.response.status != 409) throw error
            })
        , {
            initialData,
            revalidateOnFocus: false
        }
    )

    useEffect(() => {
        if (slug) {
            setCategory(flatten(categories).find(c => c.slug === slug || c.id === id));
            setChildren(categories.find((c) => c.slug === slug || c.id === id));
        }
    }, [slug, id])

    return {
        categories,
        category,
        children
    }
}