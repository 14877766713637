import { notification } from 'antd';

const notificationService = {
    success(title, message) {
        this.notify('success', title, message);
    },

    info(title, message) {
        this.notify('info', title, message);
    },

    warning(title, message) {
        this.notify('warning', title, message);
    },

    error(title, message) {
        this.notify('error', title, message);
    },

    notify(type, title, message) {
        notification[type]({
            message: title,
            description: message,
        });
    }
}

export default notificationService;