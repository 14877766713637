import useSWR from 'swr'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/navigation'

import axios from '../lib/axios'
import * as fbq from '../lib/fb-pixel'
import * as gtag from '../lib/gtag'
import notificationService from "../services/notification-service";


export const useAuth = ({ middleware, redirectToProfilePageIfAuthenticated } = {}) => {
    const router = useRouter();
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    const { data: user, error, revalidate } = useSWR('/api/v1/me/profile', () =>
        axios
            .get('/api/v1/me/profile')
            .then(res => {
                if (window.isNativeApp && window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({ profile: res.data.data, cookie: document.cookie }));
                }
                return res.data.data
            })
            .catch(error => {
                if (error.response.status != 409) throw error
            }),
        {
            shouldRetryOnError: false,
        }
    )

    const authUserInitial = () => {
        if (!user) return '';
        return user?.first_name.charAt(0) + user?.last_name.charAt(0);
    }

    const revalidateUser = () => revalidate();

    const csrf = () => axios.get('/sanctum/csrf-cookie');

    const register = async (values, actions, recaptchaRef) => {
        await csrf()
        values.phone = values.phone.replace(/\(|\)|\s|-/g, '');

        axios
            .post('/register-with-phone', values)
            .then(() => {
                fbq.trackCustom('AccountCreated');
                gtag.event({ name: 'account_created' })
                revalidate();
            })
            .then(() => router.push('/auth/verification-code'))
            .catch(error => {
                if (error.response.status != 422) throw error
                // recaptchaRef.current.reset();
                actions.setErrors(error.response.data);
            })
            .finally(() => actions.setSubmitting(false))
    }

    const sendOTP = async ({ actions, setErrors, phone }) => {
        await csrf()
        const phoneWithoutFormat = phone.replace(/\(|\)|\s|-/g, '');
        axios
            .post('/send-one-time-password', { phone: phoneWithoutFormat })
            .then((resp) => {
                sessionStorage.setItem('phone', phoneWithoutFormat);
                const redirectToVerification = (key) => {
                    const appUrl = process.env.APP_URL;
                    const verificationUrl = new URL(appUrl.concat('/auth/verification-code'));
                    verificationUrl.searchParams.set('key', key);
                    router.push(verificationUrl.href)
                }
                redirectToVerification(resp.data.key)
            })
            .catch(error => {
                if (error.response.status === 404) {
                    setErrors(error);
                    return;
                }
                if (error.response.status !== 422) throw error;
                actions.setErrors(error.response.data.errors);
            })
            .finally(() => actions.setSubmitting(false))
    }

    const validateOTP = async ({ actions, setErrors, values }) => {
        await csrf()

        axios
            .post('/validate-one-time-password', { code: values.code, key: values.key })
            .then(() => revalidate())
            .then(() => {
                sessionStorage.removeItem('phone');
                router.push('/account/profile');
            })
            .catch(error => {
                if (error.response.status != 422) throw error
                actions.setErrors(error.response.data.errors);
            })
            .finally(() => actions.setSubmitting(false))
    }

    const resendOTP = async ({ phone, setSubmitting }) => {
        await csrf()

        axios
            .post('/send-one-time-password', { phone: phone })
            .then((x) => sessionStorage.setItem('phone', phone))
            .catch(error => {
                if (error.response.status != 422) throw error
            })
            .finally(() => setSubmitting(false))
    }


    const logout = async () => {
        await csrf()

        axios.post('/logout')
            .catch(() => {
                if (error.response.status != 401) throw error
            })
            .finally(() => {
                revalidate()
                window.location.pathname = '/auth/login'
            })
    }

    const updateProfile = async ({ values, actions }) => {
        values.phone = values.phone.replace(/\(|\)|\s|-/g, '');
        axios
            .put('/api/v1/me/profile', values)
            .then(() => notificationService.success('Successfull', 'Your profile has been updated successfully'))
            .catch((error) => {
                if (error.response.status != 422) throw error
                actions.setErrors(error.response.data.errors)
            })
            .then(() => revalidate())
            .finally(() => actions.setSubmitting(false))
    }

    useEffect(() => {
        (user && !error) ? setIsAuthenticated(true) : setIsAuthenticated(false);
        if (middleware == 'auth' && error) logout()
    }, [user, error])

    useEffect(() => {
        if (middleware == 'auth' && isAuthenticated && !user.phone_verified_at) router.push('/auth/verification-code')
        if (isAuthenticated && user.phone_verified_at && redirectToProfilePageIfAuthenticated) router.push('/account/profile');
    }, [isAuthenticated])

    return {
        user,
        isAuthenticated,
        revalidateUser,
        register,
        sendOTP,
        resendOTP,
        validateOTP,
        logout,
        updateProfile,
        authUserInitial,
    }
}