import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { autocomplete } from '@algolia/autocomplete-js';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import '../styles/nvusa/agolia-autocomplete.module.css';
import algoliasearch from 'algoliasearch';

const searchClient = algoliasearch(process.env.ALGOLIA_SUGGESTIONS_APP_ID, process.env.ALGOLIA_SUGGESTIONS_SEARCH_ONLY_API_KEY);

export const Search = (props) => {
    const [initialized, setInitialized] = useState(false);
    const router = useRouter();

    function createUrl({ query, __autocomplete_qsCategory }) {
        const urlParams = new URLSearchParams();
        urlParams.set('query', query);
        if (__autocomplete_qsCategory) {
            urlParams.set('category.name', __autocomplete_qsCategory);
        }

        return `/dallas-tx?${urlParams.toString()}`;
    }

    const onSelect = (item) => {
        router.push(createUrl(item))
    }

    const onSubmit = (e) => {
        if (!e.state.query && router.pathname !== '/dallas-tx') {
            return;
        }
        router.push(createUrl({ query: e.state.query }))
    }

    const querySuggestionsPlugin = createQuerySuggestionsPlugin({
        searchClient,
        indexName: 'search_suggesstions',
        getSearchParams() {
            return {
                hitsPerPage: 10,
            };
        },
        transformSource({ source }) {
            return {
                ...source,
                onSelect({ item }) {
                    onSelect(item);
                },
            };
        },

    });

    const onReset = () => {
        const queryParams = new URLSearchParams(router.query);
        if (queryParams.has('query')) {
            queryParams.delete('query')
            router.push('/dallas-tx?category=can-tho-nails&' + queryParams.toString());
        }
    }

    useEffect(() => {
        if (!initialized) {
            const { setQuery } = autocomplete({
                container: '#autocomplete',
                placeholder: 'Search',
                openOnFocus: true,
                onSubmit,
                onReset,
                detachedMediaQuery: 'none',
                plugins: [querySuggestionsPlugin],
            });
            if (router.pathname.includes('/listing/') && router.query.query) {
                setQuery(router.query.query)
            }

            setInitialized(true);
        }
    }, [])


    return (
        <div>
            <div id="autocomplete"></div>
        </div>
    )
};
