import React from 'react';
import Link from 'next/link';

import Logo from './logo';
import { Search } from './search';
import '../styles/nvusa/header.module.scss';
import { UserProfileUtils, PostAdButton, DrawerNav } from '../components/common-ui';


function Header(props) {
    return (
        <nav className={'bg-white regular-header flex border-b border-gray-150 ' + props.className}>
            <div className="container mx-auto flex">
                <div className="flex items-center justify-between flex-wrap p-2 md:py-4 w-full">
                    <div className="text-white mr-6">
                        <Link href="/">
                            <Logo className="h-8 lg:h-10" />
                        </Link>
                    </div>
                    <div className="flex md:hidden">
                        <div className="mr-3">
                            <PostAdButton />
                        </div>
                        <DrawerNav />
                    </div>
                    {props.isShowSearchBox && (
                        <div className="mt-2 w-full lg:flex-1 lg:order-none md:order-last lg:mt-0 lg:mx-6">
                            <Search />
                        </div>
                    )}
                    <div className="ml-auto h-100 hidden md:flex">
                        <div className="mx-2"><UserProfileUtils /></div>
                        <PostAdButton />
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Header;