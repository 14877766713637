import Axios from 'axios'
import Swal from 'sweetalert2'

const axios = Axios.create({
    baseURL: process.env.API_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true,
})

axios.interceptors.response.use((response) => response, (error) => {
    const status = error.response.status
    if (status === 500) {
        Swal.fire({
            title: `${status} - Internal Server Error`,
            text: 'Sorry, Something went wrong.',
            icon: 'error',
            confirmButtonText: 'Close',
        })
        return;
    }
    if (status === 429) {
        Swal.fire({
            title: `${status} - Too Many Requests`,
            text: 'There was and error. Please try again later.',
            icon: 'error',
            confirmButtonText: 'Close',
        })
        return;
    }
    if (status === 404) {
        Swal.fire({
            title: `${status} - not found`,
            text: 'Oops, page not found.',
            icon: 'error',
            confirmButtonText: 'Close',
        })
        return;
    }
    throw error;
});

export default axios
