import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-duotone-svg-icons'

import { useAuth } from '../../hooks/auth';
import CategorySelectionDrawer from '../category-selection-drawer';

export const PostAdButton = () => {
    const { isAuthenticated } = useAuth({ middleware: 'guest' })
    const [isVisible, setIsvisible] = useState(false);
    const router = useRouter();

    const onButtonClick = () => isAuthenticated ? setIsvisible(true) : router.push('/auth/register')

    return (
        <React.Fragment>
            <button onClick={onButtonClick} className="flex items-center uppercase whitespace-no-wrap px-3 py-2 rounded bg-primary text-white text-sm font-medium lg:mt-0 hover:shadow hover:text-white">
                <FontAwesomeIcon icon={faEdit} className="mr-2 h-4" />
                Post<span className="hidden md:inline">&nbsp;FREE Ad</span>
            </button>
            {isVisible && <CategorySelectionDrawer onClose={() => setIsvisible(false)} />}
        </React.Fragment>
    )
}