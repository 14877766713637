import React, { useState, useContext } from 'react';
import { Drawer } from 'antd';
import Link from 'next/link';

import { useAuth } from '../../hooks/auth';
import StoreContext from '../../contexts/StoreContext';
import { PostAdButton } from '../common-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faUser, faLightbulbOn, faSignIn, faSignOut, faBuilding } from '@fortawesome/pro-duotone-svg-icons'
import { faBars } from '@fortawesome/pro-light-svg-icons'


export const DrawerNav = (props) => {
    const { user, logout, isAuthenticated, authUserInitial } = useAuth({ middleware: 'guest' });
    const [isOpen, setIsOpen] = useState(false);
    const { state, } = useContext(StoreContext);
    const { location } = state;


    return (
        <React.Fragment>
            <button onClick={() => setIsOpen(true)} className="rounded px-3 text-lg leading-tight border border-solid border-gray-300 text-gray-600 hover:shadow hover:text-gray-700" aria-label="menu">
                <FontAwesomeIcon icon={faBars} />
            </button>

            <Drawer
                title="Menu"
                className="drawer-no-padding drawer-sticky-header"
                placement="right"
                closable={true}
                onClose={() => setIsOpen(false)}
                visible={isOpen}
            >
                {isAuthenticated &&
                    <div className="px-4 mt-2">
                        <div className="flex border-b border-solid border-gray-200 pb-6">
                            <div className="user-avatar w-12 h-12 xl:w-20 xl:h-20 bg-green-100 text-green-400 flex justify-center items-center rounded font-bold text-2xl">
                                <span className="uppercase">{authUserInitial()}</span>
                            </div>
                            <div className="ml-3">
                                <h5 className="mb-0 text-md capitalize">{user?.first_name} {user?.last_name}</h5>
                                <h5 className="font-normal text-sm text-gray-500 mb-0">{(location) && location.city}, {(location) && location.state_code}</h5>
                            </div>
                        </div>
                        <PostAdButton />
                    </div>
                }


                {!isAuthenticated &&
                    <div className="block px-4">
                        <Link href="/auth/login" className="block w-full bg-blue-500 text-center text-white py-2 rounded hover:bg-blue-600 hover:text-white">
                            <FontAwesomeIcon icon={faSignIn} className="mr-1" /> Login
                        </Link>
                        <Link href="/auth/register" className="block w-full bg-gray-600 text-center text-white py-2 rounded mt-3 hover:bg-gray-700 hover:text-white">
                            <FontAwesomeIcon icon={faUserPlus} className="mr-1" />Create an Account
                        </Link>
                    </div>
                }

                {isAuthenticated &&
                    <div className="px-4">
                        <ul className="mt-8">
                            <li>
                                <Link href="/account/profile" className="block hover:bg-gray-200 px-3 py-2 rounded text-gray-600">
                                    <FontAwesomeIcon icon={faUser} />
                                    <span className="ml-3">Profile</span>
                                </Link>
                            </li>
                            <li>
                                <Link href="/account/ads" className="block hover:bg-gray-200 px-3 py-2 rounded text-gray-600">
                                    <FontAwesomeIcon icon={faLightbulbOn} />
                                    <span className="ml-3">Ads</span>
                                </Link>
                            </li>
                            <li>
                                <Link href="/account/businesses" className="block hover:bg-gray-200 px-3 py-2 rounded text-gray-600">
                                    <FontAwesomeIcon icon={faBuilding} />
                                    <span className="ml-3">Businesses</span>
                                </Link>
                            </li>
                        </ul>
                        <div className="text-right mt-5">
                            <button onClick={() => logout()} className="rounded-md py-2 px-3 leading-tight text-gray-600 border border-solid border-gray-200 hover:shadow hover:text-gray-700">
                                Sign Out <FontAwesomeIcon icon={faSignOut} className="ml-1" />
                            </button>
                        </div>
                    </div>
                }

            </Drawer>
        </React.Fragment>
    )
}
