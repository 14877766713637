import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaintBrush, faStore, faParachuteBox, faSpinner, faEdit } from '@fortawesome/pro-duotone-svg-icons'


function CategoryIcon({ category, className, iconClassName }) {
    const [classes, setClasses] = useState(null);
    const [iconClasses, setIconClasses] = useState(null);
    const [icon, setIcon] = useState(null);

    useEffect(() => {
        if (category.id === 1) {
            setIcon(faPaintBrush);
        } else if (category.id === 2) {
            setIcon(faStore)
        } else if (category.id === -1) {
            setIcon(faEdit)
        } else {
            setIcon(faParachuteBox)
        }
    }, [category])

    useEffect(() => {
        setClasses(className ? className : 'w-12 h-12 md:w-16 md:h-16');
        setIconClasses(iconClassName ? iconClassName : 'text-md md:text-lg');
    }, [className, iconClassName]);

    return (
        <div className={`${classes} rounded-md relative flex items-center justify-center hover:scale-105`} style={{ background: category?.colour?.iconBackground }}>
            <span className="block w-full h-full absolute transform rotate-45 rounded-md -z-10" style={{ background: category?.colour?.iconBackground, opacity: 0.3 }}></span>
            <span className={iconClasses} style={{ color: category?.colour?.iconColor }}>
                {icon && <FontAwesomeIcon icon={icon} />}
            </span>
        </div>
    )
}

export default CategoryIcon;

